<template>
  <v-overlay
    absolute
    opacity="0.95"
    color="white"
    class="d-flex align-start mt-16 mx-3 pt-11"
  >
    <v-card class="text-center d-flex flex-column align-center mt-16 pt-16" color="white" elevation="0" max-width="270">
      <v-icon
        class="mx-auto"
        size="32"
        color="primary"
      >
        $information
      </v-icon>
      <div class="text-center font-weight-medium text-subtitle-1 black--text mt-2">
        {{ $t('rtb.network.no_networks') }}
      </div>
      <c-btn
        color="primary"
        height="46"
        x-large
        class="text-subtitle-1 font-weight-medium mt-9"
        :label="$t('rtb.network.create_network')"
        @click="$emit('click:create-btn')"
      />
      <div class="secondary-darken--text text-center text-subtitle-1 mt-3">
        {{ $t('rtb.network.network_desc') }}
      </div>
    </v-card>
  </v-overlay>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'CreateNetworkOverlay',
    components: {
      CBtn
    },
    emits: ['click:create-btn']
  }
</script>
