<template>
  <v-card elevation="0">
    <v-data-table
      :loading="rtbStatisticsIsLoading"
      :headers="headers"
      :options.sync="tableOptionsMutable"
      :items="statisticsItems"
    >
      <template v-if="statisticsItems.length" #body.append>
        <total-statistics v-if="!isMobile" :headers="headers" :items="totalStatistics" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import TotalStatistics from '@/components/TotalStatistics.vue'

  export default {
    name: 'StatisticsTable',
    components: { TotalStatistics },
    props: {
      statisticsItems: {
        type: Array,
        required: true
      },
      totalStatistics: {
        type: Object,
        required: true
      },
      rtbStatisticsIsLoading: {
        type: Boolean,
        required: true
      },
      selectedGroupByOption: {
        type: Object,
        required: true
      },
      tableOptions: {
        type: Object,
        required: true
      }
    },
    emits: ['update-table-options'],
    computed: {
      headers() {
        return this.selectedGroupByOption.headers.map(({ value }) => ({
          value,
          text: this.$t(`rtb.statistics.table.${value}`)
        }))
      },
      tableOptionsMutable: {
        get() {
          return this.tableOptions
        },
        set(options) {
          this.$emit('update-table-options', options)
        }
      },
      isMobile() {
        return this.$vuetify.breakpoint.xs
      }
    }
  }
</script>
