<template>
  <c-autocomplete
    :value="selectedExternalClientIds"
    :items="filteredExternalClientIds"
    item-text="label"
    item-value="external_client_id"
    label="External Client Id"
    placeholder="External Client Id"
    clearable
    multiple
    hide-details
    @change="updateSelectedExternalClientIds($event)"
  />
</template>

<script>
  import CAutocomplete from '@clickadilla/components/ui/CAutocomplete.vue'

  export default {
    name: 'ClientId',
    components: { CAutocomplete },
    props: {
      feeds: {
        type: Array,
        required: true
      },
      selectedExternalClientIds: {
        type: Array,
        required: true
      }
    },
    emits: ['update-selected-external-client-ids'],
    computed: {
      filteredExternalClientIds() {
        return this.feeds
          .filter((feed) => feed.external_client_id)
          .map((feed) => ({
            ...feed,
            label: feed.name
              ? `${feed.name} - ${feed.external_client_id}`
              : feed.external_client_id
          }))
      }
    },
    methods: {
      updateSelectedExternalClientIds(newValue) {
        this.$emit('update-selected-external-client-ids', newValue)
      }
    }
  }
</script>
