<template>
  <v-tabs
    show-arrows
    prev-icon="$arrow-left"
    next-icon="$arrow-right"
    grow
    height="32"
  >
    <c-btn
      v-for="(item, index) in types"
      :key="item.value"
      :label="item.label"
      :class="[
        'ml-1 black--text font-weight-regular',
        { 'ml-4': item.startGroup && index !== 0 },
        { 'primary white--text': item.value === groupBy }
      ]"
      height="32"
      depressed
      @click="updateGroupBy(item.value)"
    />
  </v-tabs>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'GroupBy',
    components: {
      CBtn
    },
    props: {
      groupBy: {
        type: String,
        required: true
      },
      groupByOptions: {
        type: Object,
        required: true
      }
    },
    emits: ['update-group-by'],
    computed: {
      types() {
        return Object.entries(this.groupByOptions).reduce((acc, [optionKey, option]) => {
          acc.push({
            value: optionKey,
            label: this.$t(option.label)
          })
          return acc
        }, [])
      }
    },
    methods: {
      updateGroupBy(newValue) {
        this.$emit('update-group-by', newValue)
      }
    }
  }
</script>
