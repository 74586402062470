<template>
  <div>
    <custom-chart :series="series" :options="options" type="line" height="500" width="100%" />
    <v-row>
      <v-col class="text-center">
        <json-excel :data="excelStatisticsItems" :fields="excelColumnHeaders" name="statistic.xls">
          <c-btn color="primary" :label="$t('rtb.statistics.download_statistics')" />
        </json-excel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import JsonExcel from 'vue-json-excel'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import CustomChart from '@/components/CustomChart.vue'

  const HOURLY_FORMAT = 'HH:mm dd/MMM'

  const DAYLY_FORMAT = 'dd/MMM'

  export default {
    name: 'Chart',
    components: {
      CustomChart,
      JsonExcel,
      CBtn
    },
    props: {
      statisticsItems: {
        type: Array,
        required: true
      },
      totalStatistics: {
        type: Object,
        required: true
      },
      filters: {
        type: Object,
        required: true
      },
      selectedGroupByOption: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        defaultOptions: {
          stroke: {
            curve: 'smooth'
          }
        },
        totalsHeader: [
          'period',
          'country',
          'feed',
          'browser',
          'carrier',
          'operating_system',
          'site',
          'traffic_type'
        ]
      }
    },
    computed: {
      excelColumnHeaders() {
        return this.selectedGroupByOption.headers.reduce((acc, headerItem) => {
          if (!['ctr'].includes(headerItem.value)) {
            acc[this.$t(`rtb.statistics.table.${headerItem.value}`)] = headerItem.value
          }
          return acc
        }, {})
      },
      excelStatisticsItems() {
        const exelHeaders = Object.values(this.excelColumnHeaders)
        const newStatisticsItems = exelHeaders.reduce((acc, headerItem) => {
          acc[headerItem] = this.totalStatistics[headerItem]
          if (this.totalsHeader.includes(headerItem)) {
            acc[headerItem] = this.$t('main.total')
          }
          return acc
        }, {})
        return [...this.statisticsItems, newStatisticsItems]
      },
      yaxis() {
        return [
          {
            title: {
              text: this.$t('rtb.statistics.table.cost')
            }
          },
          {
            opposite: true,
            title: {
              text: this.$t('rtb.statistics.table.impressions')
            }
          },
          {
            title: {
              text: this.$t('rtb.statistics.table.clicks')
            }
          }
        ]
      },
      xaxis() {
        if (this.filters.isHourly) {
          return {
            type: 'datetime',
            labels: {
              format: HOURLY_FORMAT
            }
          }
        }
        if (this.filters.groupBy === 'period') {
          return {
            type: 'datetime',
            labels: {
              format: DAYLY_FORMAT
            }
          }
        }
        return {
          type: 'category'
        }
      },
      costsSeries() {
        const series = this.statisticsItems.map((item) => parseFloat(item.cost))
        return {
          name: this.$t('rtb.statistics.table.cost'),
          type: this.filters.groupBy === 'period' ? 'area' : 'column',
          data: series
        }
      },
      impressionsSeries() {
        const series = this.statisticsItems.map((item) => parseFloat(item.impressions))
        return {
          name: this.$t('rtb.statistics.table.impressions'),
          type: this.filters.groupBy === 'period' ? 'line' : 'column',
          data: series
        }
      },
      clicksSeries() {
        const series = this.statisticsItems.map((item) => parseFloat(item.clicks))
        return {
          name: this.$t('rtb.statistics.table.clicks'),
          type: this.filters.groupBy === 'period' ? 'line' : 'column',
          data: series
        }
      },
      labels() {
        if (this.statisticsItems[0] && this.filters.groupBy in this.statisticsItems[0]) {
          return this.statisticsItems.map((item) => item[this.filters.groupBy])
        }
        return []
      },
      series() {
        return [this.costsSeries, this.impressionsSeries, this.clicksSeries]
      },
      options() {
        return {
          ...this.defaultOptions,
          labels: this.labels,
          yaxis: this.yaxis,
          xaxis: this.xaxis,
          fill: {
            type: 'solid',
            opacity: this.filters.groupBy === 'period' ? [0, 1] : [1, 1]
          },
          tooltip: {
            x: {
              show: true,
              format: this.filters.isHourly ? HOURLY_FORMAT : DAYLY_FORMAT,
              formatter: undefined
            }
          }
        }
      }
    }
  }
</script>
