export default function getRtbStatisticsGroupByOptions() {
  return {
    period: {
      label: 'rtb.statistics.dates',
      headers: ['period', 'cost', 'impressions', 'clicks', 'ctr']
    },
    feed: {
      label: 'rtb.statistics.feed',
      headers: ['feed', 'cost', 'impressions', 'clicks', 'ctr']
    },
    source_id: {
      label: 'rtb.statistics.source_id',
      headers: ['source_id', 'cost', 'impressions', 'clicks', 'ctr']
    },
    country: {
      label: 'rtb.statistics.country',
      headers: ['country', 'cost', 'impressions', 'clicks', 'ctr']
    }
  }
}
