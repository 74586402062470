<template>
  <v-card :loading="rtbStatisticsIsLoading" elevation="0">
    <v-card-title class="text-body-1 black white--text font-weight-medium py-3 pl-6">
      {{ $t('rtb.statistics.statistics_settings') }}
    </v-card-title>
    <v-card-text class="py-0">
      <v-container class="px-0">
        <v-row align="center">
          <v-col cols="12" md="4">
            <date-picker-select :value="filters.dates" with-presets @input="updateFilters('dates', $event)" />
          </v-col>
          <v-col cols="12" md="4">
            <client-id
              :feeds="feeds"
              :selected-external-client-ids="filters.selectedExternalClientIds"
              @update-selected-external-client-ids="updateFilters('selectedExternalClientIds', $event)"
            />
          </v-col>
          <v-col cols="12" md="4">
            <c-autocomplete
              :label="$t('main.ad_format')"
              :value="filters.billingTypes"
              :items="billingTypes"
              :loading="billingTypesAreLoading"
              :placeholder="$t('main.ad_format')"
              :all-selected-is-active="false"
              item-text="name"
              item-value="name"
              hide-details
              clearable
              @change="updateFilters('billingTypes', $event)"
            />
          </v-col>
        </v-row>
        <v-row v-if="filters.groupBy === 'period'" align="center">
          <v-col cols="12" md="4">
            <c-switch
              dense
              :input-value="filters.isHourly"
              :label="$t('rtb.statistics.hourly')"
              @change="updateFilters('isHourly', $event)"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapState } from 'vuex'
  import CSwitch from '@clickadilla/components/ui/CSwitch.vue'
  import CAutocomplete from '@clickadilla/components/ui/CAutocomplete.vue'
  import DatePickerSelect from '@clickadilla/components/DatePickerSelect/index.vue'
  import ClientId from '@/views/RtbStatistics/Settings/ClientId.vue'
  import rtbEndpointsRepository from '@/services/repositories/rtb-endpoints-repository.js'
  import handleErrors from '@/services/handleErrors.js'
  import { networksRepository } from '@/services/repository-factory.js'

  export default {
    name: 'Settings',
    components: {
      ClientId,
      CAutocomplete,
      CSwitch,
      DatePickerSelect
    },
    props: {
      rtbStatisticsIsLoading: {
        type: Boolean,
        required: true
      },
      filters: {
        type: Object,
        required: true
      }
    },
    emits: ['update-filters'],
    data() {
      return {
        feeds: [],
        billingTypes: [],
        billingTypesAreLoading: false
      }
    },
    computed: {
      ...mapState('settings', ['adFormats'])
    },
    created() {
      this.fetchFeeds()
      this.fetchBillingTypes()
    },
    methods: {
      async fetchFeeds() {
        const params = { 'columns[0][data]': 'id' }
        try {
          const { data } = await rtbEndpointsRepository.table(params)
          this.feeds = data
        } catch (error) {
          handleErrors(error)
        }
      },
      async fetchBillingTypes() {
        this.billingTypesAreLoading = true
        try {
          this.billingTypes = await networksRepository.billingTypes()
        } catch (error) {
          handleErrors(error)
        }
        this.billingTypesAreLoading = false
      },
      updateFilters(key, value) {
        this.$emit('update-filters', { ...this.filters, [key]: value })
      }
    }
  }
</script>
