<template>
  <v-row>
    <v-col cols="12">
      <settings
        :rtb-statistics-is-loading="rtbStatisticsIsLoading"
        :filters="filters"
        @update-filters="updateFilters($event)"
      />
    </v-col>

    <v-col cols="12">
      <v-card elevation="0">
        <v-card-title class="pa-0">
          <group-by
            :group-by="filters.groupBy"
            :group-by-options="groupByOptions"
            @update-group-by="updateGroupBy($event)"
          />
        </v-card-title>

        <v-divider />

        <v-card-text v-if="chartIsShown">
          <chart
            :statistics-items="statisticsItems"
            :total-statistics="totalStatistics"
            :selected-group-by-option="selectedGroupByOption"
            :filters="filters"
          />
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <statistics-table
        :statistics-items="statisticsItems"
        :total-statistics="totalStatistics"
        :rtb-statistics-is-loading="rtbStatisticsIsLoading"
        :selected-group-by-option="selectedGroupByOption"
        :table-options="filters.tableOptions"
        @update-table-options="updateFilters({ ...filters, tableOptions: $event })"
      />
    </v-col>

    <create-network-overlay
      v-if="overlayIsShown"
      @click:create-btn="$router.push({ name: routeNames.RTB_CREATE_NETWORK })"
    />
  </v-row>
</template>

<script>
  import debounce from 'debounce'
  import GroupBy from './GroupBy.vue'
  import Chart from './Chart.vue'
  import CreateNetworkOverlay from './CreateNetworkOverlay.vue'
  import Settings from './Settings/Index.vue'
  import StatisticsTable from './StatisticsTable.vue'
  import { rtbStatisticsRepository } from '@/services/repository-factory.js'
  import routeNames from '@/types/route-names.js'
  import handleErrors from '@/services/handleErrors.js'
  import getInitialDates from '@/services/utils/get-initial-dates.js'
  import getRtbStatisticsGroupByOptions from '@/services/utils/get-rtb-statistics-group-by-options.js'

  const headersLabels = {
    period: 'Date',
    country: 'Country',
    feed: 'Feed',
    source_id: 'Source Id',
    impressions: 'Impressions',
    cost: 'Cost $'
  }

  export default {
    name: 'RtbStatistics',
    components: {
      Settings,
      StatisticsTable,
      GroupBy,
      Chart,
      CreateNetworkOverlay
    },
    data() {
      return {
        statisticsItems: [],
        totalStatistics: {},
        rtbStatisticsIsLoading: false,
        filters: {
          dates: getInitialDates(-14),
          selectedExternalClientIds: [],
          groupBy: 'period',
          tableOptions: {
            sortBy: ['period'],
            sortDesc: [true]
          },
          billingTypes: [],
          isHourly: false
        },
        errors: {},
        groupByOptions: getRtbStatisticsGroupByOptions(),
        debouncedFetchStatistics: () => {},
        routeNames
      }
    },
    computed: {
      statisticsRequest() {
        return {
          timeframe: 'day',
          startDate: this.filters.dates.start,
          endDate: this.filters.dates.end,
          externalClientIds: this.filters.selectedExternalClientIds,
          billingTypes: this.filters.billingTypes,
          ...this.filters.tableOptions,
          groupBy: this.filters.isHourly ? 'hour' : this.filters.groupBy
        }
      },
      chartIsShown() {
        return !['source_id', 'country'].includes(this.filters.groupBy)
      },
      overlayIsShown() {
        return !!this.errors.network && !this.rtbStatisticsIsLoading
      },
      selectedGroupByOption() {
        const selectedGroupByOption = {
          label: this.groupByOptions[this.filters.groupBy].label,
          headers: this.groupByOptions[this.filters.groupBy].headers.reduce((acc, headerKey) => {
            acc.push({
              text: headersLabels[headerKey],
              value: headerKey
            })
            return acc
          }, [])
        }

        return selectedGroupByOption
      }
    },
    created() {
      this.debouncedFetchStatistics = debounce(this.fetchStatistics, 700)
    },
    methods: {
      async fetchStatistics() {
        this.rtbStatisticsIsLoading = true
        this.statisticsItems = []
        this.totalStatistics = {}
        try {
          const response = await rtbStatisticsRepository.list(this.statisticsRequest)
          this.statisticsItems = response.items
          this.totalStatistics = response.totals
        } catch (error) {
          this.errors = handleErrors(error)
        }
        this.rtbStatisticsIsLoading = false
      },
      async updateFilters(newValue) {
        this.filters = newValue
        this.debouncedFetchStatistics()
      },
      async updateGroupBy(newValue) {
        this.statisticsItems = []
        this.totalStatistics = {}
        this.filters.isHourly = false
        this.updateFilters({ ...this.filters, groupBy: newValue })
      }
    }
  }
</script>
